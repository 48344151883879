import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'

// t
export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = {
    ...jwtDefaultConfig
  }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  constructor(jwtOverrideConfig) {
    this.jwtConfig = {...this.jwtConfig, ...jwtOverrideConfig}

    axios.defaults.baseURL = 'https://systemapi2.mtcgame.com'

    // ** Request Interceptor
    axios.interceptors.request.use(
      config => {
        // ** Get token from localStorage
        const accessToken = (this.getToken() || '').replace(/['"]+/g, '')
        config.headers['Content-Type'] = 'application/json'
        config.headers['Accept'] = 'application/json'

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }

        return config
      },
      error => Promise.reject(error)
    )

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      response => response.data,
      error => {
        // ** const { config, response: { status } } = error
        const { response } = error

        // ** if (status === 401) {
        if (response && response.status === 401) {
          window.location = '/misc/not-authorized'
         // dispatch(handleLogout())
        }
        return Promise.reject(error)
      }
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return axios.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args)
  }
  sendSms({type, userId}) {
    return axios.post(`/api/User/SendSms?type=${type}&userId=${userId}`)
  }
  sendEmail({type, userId}) {
    return axios.post(`/api/User/SendEmail?type=${type}&userId=${userId}`)
  }

  buyXbox({externalId, orderId, xboxStoreLocation}) {
    return axios.post(`/api/OrderDetails/BuyFromXbox?externalId=${externalId}&orderId=${orderId}&xboxStoreLocation=${xboxStoreLocation}`)
  }

  stockComplete({codeCategoryId, otp}) {
    return axios.post(`/api/OrderDetails/StockComplate?codeCategoryId=${codeCategoryId}&otp=${otp}`)
  }

  getProducts(args) {


    return axios.get(`${this.jwtConfig.getProductsList}`,
        {
          params:{...args}
        })
  }

  getProductDetail({orderId}) {
    return axios.post(`api/OrderDetails/OrderDetail?orderId=${orderId}`, {})
  }

  deliverTopUp({userId, orderId}) {
    return axios.post(`api/OrderDetails/DeliveryTopUp?orderId=${orderId}&userId=${userId}`, {})
  }

  userQuality(data) {
    const {ipAddress, paymentNotifyId, phone, mail} = data
    console.log(ipAddress, "ipAddress ipAddress ipAddress ipAddress")
    return axios.post(`api/OrderDetails/UserQuality?ipAddress=${ipAddress}&paymentNotifyId=${paymentNotifyId}&phone=${phone}&mail=${mail}`, {})
  }

  lastPaymentTen({userId}) {
    return axios.post(`api/OrderDetails/LastPaymentTen?userId=${userId}`, {})
  }

  OrderDetailClose({orderId}) {
    return axios.post(`api/OrderDetails/OrderDetailClose?orderId=${orderId}`, {})
  }

  userBan({orderId}) {
    return axios.post(`api/OrderDetails/UserBan?orderId=${orderId}`, {})
  }

  kksChange({userId}) {
    return axios.post(`api/OrderDetails/ChangeCreditCardStatus?userId=${userId}`, {})
  }

  rejectOrder({orderId, returnReason}) {
    return axios.post(`api/OrderDetails/Cancel?orderId=${orderId}&returnReason=${returnReason}`, {})
  }

  orderConfirm({orderId}) {
    return axios.post(`api/OrderDetails/Stock?orderId=${orderId}`, {})
  }

  similarOrderConfirm({orderId}) {
    return axios.post(`api/OrderDetails/StockAllSimilar?orderId=${orderId}`, {})
  }

  similarOrderReject({orderId, returnReason}) {
    return axios.post(`api/OrderDetails/CancelAllSimilar?orderId=${orderId}&returnReason=${returnReason}`, {})
  }

  refreshToken() {
    return axios.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken()
    })
  }
}
